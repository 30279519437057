<template>
  <div v-if="isLoading">
    <section>
      <div class="datatable-toolbar">
        <div class="buttons">
          <button
            class="button h-button is-success is-raised"
            @click.prevent="cariChannelPda"
          >
            <i class="fas fa-search"></i>
            Cari Data
          </button>
        </div>
      </div>
    </section>
    <br />
    <app-spinner />
  </div>
  <div v-else>
    <section>
      <div class="datatable-toolbar">
        <div class="buttons">
          <button
            class="button h-button is-success is-raised"
            @click.prevent="cariChannelPda"
          >
            <i class="fas fa-search"></i>
            Cari Data
          </button>
        </div>
      </div>
    </section>
    <br />
    <section class="columns">
      <div class="column is-12 tma">
        <div class="card-debit">
          <div class="card">
            <div class="card-header"></div>
            <div class="card-conten chart">
              <v-chart autoresize class="chart" :option="awlr" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <br />

    <div class="debit-tabs">
      <section class="columns">
        <div class="column is-12 tma">
          <div class="card-debit">
            <div class="card">
              <div class="card-header"></div>
              <!-- Datatable -->
              <div class="table-wrapper" v-if="isLoading">
                <app-spinner />
              </div>
              <div v-else>
                <div class="table-wrapper">
                  <b-table
                    class="table is-datatable is-hoverable table-is-bordered"
                    :data="pda"
                    :columns="columnsPda"
                    focusable
                    paginated
                    :total="totalPda"
                    :per-page="perPage"
                    @page-change="onPageChange"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    :default-sort-direction="defaultSortOrder"
                    :default-sort="[sortField, sortOrder]"
                    @sort="onSort"
                    icon-pack="fas"
                    icon-left="chevron-right"
                  >
                    <b-table-column
                      field="tanggal"
                      label="Tanggal"
                      v-slot="props"
                    >
                      {{ props.row.tanggal }}
                    </b-table-column>

                    <b-table-column field="ch1" label="TMA (M)" v-slot="props">
                      {{ props.row.tma }}
                    </b-table-column>

                    <template #empty>
                      <div class="has-text-centered">Tidak Ada Data</div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import moment from "moment";
import AppSpinner from "../shared/AppSpinner.vue";
export default {
  name: "ModalTablePdManual",
  components: {
    AppSpinner,
  },
  props: {
    registerId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      pda: [],
      totalPda: 0,
      perPage: 10,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      columnsPda: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "tma",
          label: "TMA (Cm)",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: true,
      hasMobileCards: true,
      awlr: {},
      waspada: 5,
      siaga: 250,
      awas: 560,
      tinggiTanggul: 1000,
    };
  },
  created() {
    this.isLoading = true;
  },
  computed: {
    ...mapState({
      hidrologiPdaLanding: (state) => state.hidrologiPdaLanding.items,
      landingLevel: (state) => state.landingLevel.item,
    }),
  },
  methods: {
    ...mapActions("hidrologiPdaLanding", ["fetchPdaManual"]),
    /*
     * Load async data
     */
    loadAsyncData() {
      this.fetchPdaManual(this.registerId).then((resp) => {
        if (resp != null) {
          this.pda = [];
          let currentTotal = resp.length;
          if (currentTotal / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          this.totalPda = currentTotal;

          resp.forEach((item) => {
            this.isLoading = false;
            // item.tanggal = moment(String(item.tanggal)).format(
            //   "YYYY-MM-DD HH:mm:ss"
            // );
            this.pda.push(item);
          });

          this.awlr = {
            title: {
              left: "1%",
            },
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "7%",
              right: "15%",
              bottom: "10%",
              top: "10%",
            },
            xAxis: {
              data: resp.map((r) => r.tanggal).reverse(),
            },
            yAxis: {
              type: "value",
              axisLabel: {
                formatter: "{value} cm",
              },
            },

            dataZoom: [
              {
                startValue: "1970-01-01",
              },
              {
                type: "inside",
              },
            ],
            visualMap: {
              top: 10,
              right: 10,
              pieces: [
                {
                  lte: this.waspada,
                  color: "#63b208",
                },
                {
                  lte: this.siaga,
                  color: "#f7f711",
                },
                {
                  lte: this.awas,
                  color: "#FD0100",
                },
                {
                  gt: this.tinggiTanggul,
                  color: "#7f8c8d",
                },
              ],
              outOfRange: {
                color: "#e74c3c",
              },
            },
            series: {
              name: "TMA",
              type: "line",
              data: resp.map((r) => r.tma).reverse(),
            },
          };
        }
      });
    },
    cariChannelPda() {
      this.$router.push(`/caridata/hidrologi/pda/${this.registerId}`);
    },
    addChannelMethod(chan) {
      this.addChannel(chan);
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cariChannel() {
      this.$router.push(`/lihatdata/${this.registerId}`);
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
.fa-search {
  margin-right: 10px;
}
.chart {
  height: 400px;
  width: 100%;
}
</style>
